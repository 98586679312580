import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 104px;
`;

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  padding-bottom: 26px;
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 80px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.primary};

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 50px;
    line-height: 65px;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
    font-size: 40px;
    line-height: 55px;
  }
`;
export const Subtitle = styled.p`
  margin: 0;
  padding: 0;
  padding-bottom: 52px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #1f1d1c;
`;

export const TextContainer = styled.div`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  padding-bottom: 104px;
  max-width: 980px;
  width: 100%;
  align-self: center;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Image404Container = styled.div`
  width: 100%;
  max-width: 488px;
  padding-bottom: 52px;
  text-align: center;

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    svg {
      width: 346px;
    }
  }
`;

export const PlugContainer = styled.div`
  padding-top: 52px;
  position: absolute;
  left: 0;

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    left: -200px;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    left: -100px;
  }
`;

export const WorkContainer = styled.div`
  padding: 202px 0 52px 0;
  max-width: 980px;
  width: 100%;
  align-self: center;

  @media only screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
    padding: 104px 0 52px 0;
  }
`;
