import React from 'react';
import PropTypes from 'prop-types';

import Link from 'atomic-design/atoms/link';
import { RightArrowAlt } from '@styled-icons/boxicons-regular/RightArrowAlt';
import { Container, Text, ColorText, Arrow } from './styles';

const LinkTextBar = ({ text, colorText, to }) => {
  return (
    <Container>
      <Link to={to} rel="noreferrer">
        <Text>
          {text}
          {colorText.split(' ').map(t => (
            <ColorText key={t}>{`${t} `}</ColorText>
          ))}
          <Arrow>
            <RightArrowAlt size="30px" />
          </Arrow>
        </Text>
      </Link>
    </Container>
  );
};

LinkTextBar.propTypes = {
  text: PropTypes.string.isRequired,
  colorText: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default LinkTextBar;
