import React from 'react';
import PropTypes from 'prop-types';

import Work from 'atomic-design/molecules/work';
import LinkTextBar from 'atomic-design/atoms/link-text-bar';
import { Container, Title, WorkContainer, LinkTextBarContainer, WorkBarContainer } from './styles';
import { validateCaseStudyEntry } from '../../../../common/validations';
import MissingDataCard from 'atomic-design/atoms/missing-data-card'

const WorkLink = ({ title, work, height, maxWidthText }) => {
  const requiredFields = ['title', 'slug', 'LimitedAbstract', 'thumbnail'];
  const validatedWork = validateCaseStudyEntry(work.node);
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <WorkBarContainer>
        <WorkContainer>
          {!validatedWork || validatedWork.error ? (
            <MissingDataCard key={work.node.slug} obj={work.node} reqFields={requiredFields} ></MissingDataCard>
          ) : (
            <Work
              slug={work.node.slug}
              contentful_id={work.node.thumbnail.contentful_id}
              title={work.node.title}
              subtitle={work.node.subtitle}
              type={work.node.LimitedAbstract}
              boxShadow="0px -4px 30px rgba(93, 115, 245, 0.2)"
              margin="0 0 26px 0"
              height={height}
              maxWidthText={maxWidthText}
            />
          )}
        </WorkContainer>
        <LinkTextBarContainer>
          <LinkTextBar text="Take a look at what we’ve been" colorText=" working on" to={'/work'} />
        </LinkTextBarContainer>
      </WorkBarContainer>
    </Container>
  );
};

WorkLink.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  work: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    thumbnail: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }),
    hero: PropTypes.shape({
      title: PropTypes.string.isRequired,
      contentful_id: PropTypes.string.isRequired,
    }),
    body: PropTypes.shape({
      json: PropTypes.instanceOf(Object).isRequired,
    }),
  }),
};

WorkLink.defaultProps = {
  title: '',
  maxWidthText: '',
  height: '',
};

export default WorkLink;
