import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.h2`
  margin: 0 0 26px 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.01em;
  color: #5d73f5;

  @media only screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
    font-size: 30px;
    line-height: 45px;
  }
`;

export const WorkBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const WorkContainer = styled.div`
  flex-basis: 0;
  flex-grow: 2;
`;
export const LinkTextBarContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
`;
