import React from 'react';

import { graphql, Link } from 'gatsby';
import MetaTags from 'core/meta-tags/index';

import Layout from 'core/layout';
import WorkLink from 'atomic-design/organisms/work-link';
import Button from 'atomic-design/atoms/button';
import { Plug, ImageNotFound } from 'ui/svg';
import MainContainer from 'atomic-design/atoms/main-container';
import PageSection from 'atomic-design/atoms/page-section';

import {
  Title,
  Subtitle,
  Container,
  TextContainer,
  Image404Container,
  PlugContainer,
  WorkContainer,
  ButtonContainer,
  ImagesContainer,
} from '../styles/pages/not-found-styles';

export const data = graphql`
  query {
    allContentfulCaseStudy(sort: { fields: [publishedDate], order: DESC }, limit: 1) {
      edges {
        node {
          featured
          title
          slug
          subtitle
          LimitedAbstract
          thumbnail {
            contentful_id
            title
            file {
              url
            }
          }
        }
      }
    }
    allContentfulPage(filter: { url: { eq: "404" } }) {
      edges {
        node {
          headerTitle {
            json
          }
          headerSubtitle
          headerDescription {
            headerDescription
          }
          metatagTitle
          metatagDescription
        }
      }
    }
  }
`;

const NotFoundPage = ({ data, location }) => {
  const work = data.allContentfulCaseStudy.edges[0];
  const metatagTitle = data.allContentfulPage.edges[0].node.metatagTitle;
  const metatagDescription = data.allContentfulPage.edges[0].node.metatagDescription;
  return (
    <Layout bodyType="list" path={location.pathname}>
      <MetaTags
        title={metatagTitle}
        description={metatagDescription}
        charSet="utf-8"
        viewport="width=device-width, initial-scale=1"
        type="website"
      />
      <Container>
        <MainContainer>
          <TextContainer>
            <Title>We are sorry</Title>
            <Subtitle>We can’t find the page you are looking for.</Subtitle>
          </TextContainer>
        </MainContainer>
        <ImagesContainer>
          <Image404Container>
            <ImageNotFound />
            <PlugContainer>
              <Plug />
            </PlugContainer>
          </Image404Container>
        </ImagesContainer>
        <PageSection>
          <WorkContainer>
            <WorkLink
              title="You may want to take a look around"
              work={work}
              height="281px"
              maxWidthText="637px"
            />
          </WorkContainer>
          <ButtonContainer>
            <Link to="/">
              <Button aria-label="back to HomePage">Take me home</Button>
            </Link>
          </ButtonContainer>
        </PageSection>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
