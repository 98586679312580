import styled from 'styled-components';
import { RightArrowAlt } from '@styled-icons/boxicons-regular/RightArrowAlt';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 85px;
  max-height: 85px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -4px 30px rgba(93, 115, 245, 0.2);
  border-radius: 4px;
  padding: 0 10px 0 20px;

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding-top: 26px;
    padding-bottom: 26px;
    height: fit-content;
    max-height: fit-content;
  }
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #4f4f4f;
`;

export const ColorText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  letter-spacing: -0.01em;
  background: linear-gradient(242.59deg, #5d73f5 10%, #f46974 100.58%, #f5726a 100.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Arrow = styled.span`
  ${RightArrowAlt} * {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
